import { observable } from 'mobx';
import { injectable } from 'inversify';
import { LoadingState } from './LoadingState';

@injectable()
export class LoadingStore {
    @observable
    private _loadingIdentifiers: Record<string, LoadingState> = {};

    public getLoadingState(id: string): LoadingState {
        return this._loadingIdentifiers[id] ?? LoadingState.INITIAL;
    }

    public setLoadingState(id: string, state: LoadingState): void {
        this._loadingIdentifiers[id] = state;
    }

    public isLoading(id: string): boolean {
        return this._loadingIdentifiers[id] === LoadingState.INITIAL
            || this._loadingIdentifiers[id] === LoadingState.LOADING;
    }
}
