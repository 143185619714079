import { injectable } from 'inversify';
import { observable } from 'mobx';
import { GlobalStoreContract } from './GlobalStoreContract';

@injectable()
export class GlobalStore implements GlobalStoreContract {
    @observable
    private _menuCollapsed = false;

    public get menuCollapsed(): boolean {
        return this._menuCollapsed;
    }

    public toggleMenu(): void {
        this._menuCollapsed = !this._menuCollapsed;
    }
}
