import Rollbar from 'rollbar';
import { injectable } from 'inversify';
import config from '../../../config';

@injectable()
export default class LogService {
    private rollbar: Rollbar;

    constructor() {
        this.rollbar = new Rollbar({
            accessToken: config.logging.rollbar.accessToken,
            captureUncaught: config.logging.rollbar.captureUncaught,
            captureUnhandledRejections: config.logging.rollbar.captureUnhandledRejections,
            environment: process.env.NODE_ENV || 'development'
        });
    }

    error(error: Rollbar.LogArgument) {
        this.rollbar.error(error);
    }
}
