import { GuardFunction } from 'react-router-guards';
import Container from '../Container';
import { PermissionServiceContract } from '../services/permissions/PermissionServiceContract';

const checkPermissions: GuardFunction = (to, _from, next) => {
    const permissionService = Container.get<PermissionServiceContract>('permissions');

    if (to.meta.permissions) {
        let canGo = true;
        canGo = permissionService.hasPermission(to.meta.permissions);

        if (!canGo) {
            return next.redirect('/home');
        }
    }

    return next();
};

export default checkPermissions;
