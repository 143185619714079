import db from '../db'

class SightRepository {

    /**
     * @param {array} ids - Array of ids used for filtering
     * @returns {object} 
     */

    static async getSingleSightById(sightId) {
        
            let location = await db.sights.where({id: sightId}).first( sight => sight);
            return location;
    }

    /**
     * @param {array} ids - Array of ids used for filtering
     * @returns {array} Array of strings containing the adress of the sight
     */
    static async getSightsById(sightIds) {
        try {
            let routeParams = await Promise.all(sightIds.map( async sightId => {
                let routeParam = db.sights.where({id: sightId}).first( sight => sight);
                return routeParam;
            }))
            return routeParams
         } catch{}
    }   

    /**
     * @param {string} id - Id used for sight reference
     * @param {string} label - Name used for showing waypoints
     * @param {string} description
     * @param {string} description_link
     * @param {string} placeId - place id used by google maps api
     * @param {string} address - optional for backwards compatibility
     * @param {boolean} isStop - should route split at given sight
     */
    static async postSight(id,label, description, description_link, placeId,address, isStop) {
        db.sights.add({
            id,
            label,
            description,
            description_link,
            placeId,
            address : address ? address : null,
            isStop : isStop ? isStop : false
        })
    }

    /**
     * @param {string} id - Id used for sight refference
     */
    static async deleteSight(id) {
        db.sights.delete(id)
    }
}

export default SightRepository;