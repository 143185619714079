import React from 'react';
import Page from '../Page';
import RouteSelect from '../../shared/routes/route-select';

class Routes extends React.Component {
    render () {
        return(
            <Page title="Routes">
                <RouteSelect/>
            </Page>
        )
    }
}

export default Routes;