import { inject, injectable } from 'inversify';
import { PermissionServiceContract } from './PermissionServiceContract';
import { AuthStoreContract } from '../../stores/auth/AuthStoreContract';

@injectable()
export class PermissionService implements PermissionServiceContract {
    private auth: AuthStoreContract;

    public constructor(
        @inject('auth') app: AuthStoreContract
    ) {
        this.auth = app;
    }

    public hasPermission(permission: string): boolean {
        if (!this.auth.user?.permissions) {
            return false;
        }

        const { permissions } = this.auth.user;

        // eslint-disable-next-line no-restricted-syntax
        for (const route of Object.keys(this.auth.user.permissions)) {
            if (route === permission) {
                return true;
            }

            if (typeof permissions[route] === 'object') {
                if (Object.prototype.hasOwnProperty.call(permissions[route], permission)) {
                    return true;
                }
            }
        }

        return false;
    }
}
