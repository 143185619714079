import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoresProvider } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import './resources/style/index.scss';
import { StoresRoot } from './app/stores/StoresRoot';
import nl from './resources/lang/nl';

ReactDOM.render(
    <React.StrictMode>
        <StoresProvider {...StoresRoot.getInstance().getStores()}>
            <IntlProvider
                locale="nl"
                textComponent={React.Fragment}
                messages={nl}
            >
                <App />
            </IntlProvider>
        </StoresProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
