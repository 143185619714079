import Dexie from 'dexie';

const db = new Dexie('SightSeeingDB');
db.version(2).stores({
    routeGroups: 'id,name,routeIds',
    routes: 'id,name,start,end,sightIds',
    sights: 'id,address,placeId'
});

db.version(3).stores({
    routeGroups: 'id,name,routeIds',
    routes: 'id,description,description_link,name,start,end,sightIds,travelModes',
    sights: 'id,label,description, description_link,placeId,address,isStop'
});

export default db;
