import * as React from 'react';
import IntlMessageFormat from 'intl-messageformat';
import messages from '../../../resources/lang/nl';
import Str from '../helpers/Str';

export type Errors = Record<string, Array<string>>;

export type KeyedErrors = { errors: Errors };

declare type Messages = { [key: string]: string }

export class Validator<T extends HTMLElement> {
    private element: T;
    private readonly messages: Messages;
    private namespace = '';
    private valid = true;
    private errors: Errors = {};
    private static RULE = 0;
    private static CONDITION = 1;
    private data?: NodeListOf<HTMLElement>;

    constructor(
        element: React.RefObject<T>,
        namespace = ''
    ) {
        this.element = element.current as T;
        this.namespace = namespace;
        this.messages = this.getValidatorMessages();
    }

    // @todo refactor
    private getValidatorMessages(): Record<string, string> | {} {
        return Object.keys(messages)
            .filter((key: string) => {
                if (this.namespace) {
                    return key.startsWith(`validator.${this.namespace}`);
                }

                return key.startsWith('validator');
            })
            .reduce((obj: Messages, key: string) => {
                const ruleParts = key.split('.');
                obj[ruleParts[ruleParts.length - 1]] = messages[key];
                return obj;
            }, {});
    }

    public validate(): boolean {
        this.data = this.element.querySelectorAll('[data-validate]');

        for (let i = 0; i < this.data.length; i++) {
            const validationRules = (this.data[i].getAttribute('data-validate') || '').split('|');

            validationRules.forEach((rule: string) => {
                if (rule.length && rule !== 'true' && rule !== 'false') {
                    this.validateRule(this.data![i], rule);
                }
            });
        }

        return this.valid;
    }

    private validateRule(child: HTMLElement, rule: string): void {
        const ruleAndCondition = rule.split(':');
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const method = this[
            `validate${Str.studly(ruleAndCondition[Validator.RULE])}`
        ];

        if (typeof method !== 'function'
            || !method.call(this, child, ...(ruleAndCondition[Validator.CONDITION] || '').split(','))
        ) {
            this.valid = false;

            this.addError(child, ruleAndCondition);
        }
    }

    public clear(): void {
        this.valid = true;
        this.errors = {};
    }

    private addError(child: HTMLElement, ruleAndCondition: Array<string>): void {
        const name = child.getAttribute('name') || child.getAttribute('data-shim-name') || '';

        if (!Object.prototype.hasOwnProperty.call(this.errors, name)) {
            this.errors[name] = [];
        }

        const error = (
            this.messages[ruleAndCondition[Validator.RULE]]
                ? new IntlMessageFormat(
                    this.messages[ruleAndCondition[Validator.RULE]]
                ).format({
                    name: child.getAttribute('data-validate-text') || child.getAttribute('name'),
                    value: (ruleAndCondition[Validator.CONDITION] || '')
                }) as string
                : ruleAndCondition[Validator.RULE]
        );

        this.errors[name].push(error);
    }

    private addErrors(errors: Errors): void {
        Object.keys(errors).forEach((key: string) => {
            this.errors[key] = errors[key];
        });
    }

    public getErrors(): Errors {
        return this.errors;
    }

    public has(key: string): boolean {
        return !!this.errors[key];
    }

    public first(key: string): string | null {
        if (typeof this.errors[key] !== 'undefined') {
            return this.errors[key][0];
        }

        return null;
    }

    public setErrors(errors: Errors): void {
        this.errors = errors;
    }

    protected getElementWhereName(name: string) {
        if (!this.data?.length) {
            return null;
        }

        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].getAttribute('name') === name
                || this.data[i].getAttribute('data-shim-name') === name
            ) {
                return this.data[i];
            }
        }

        return null;
    }
}

export default Validator;
