import { GuardFunction } from 'react-router-guards';
import Container from '../Container';
import { NotificationStoreContract } from '../stores/notifications/NotificationStoreContract';

const clearNotifications: GuardFunction = (_to, _from, next) => {
    Container.get<NotificationStoreContract>('notifications').clear();

    next();
};

export default clearNotifications;
