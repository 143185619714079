import * as React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { GuardedRoute, GuardedRouteProps, GuardProvider } from 'react-router-guards';
import {
    faRoute,
    faPlus,
    faHome,
    faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import Login from '../components/pages/auth/Login';
import LogoutPage from '../components/pages/Logout/index';
import auth from './guards/auth';
import modifyHead from './guards/modifyHead';
import clearNotifications from './guards/clearNotifications';
import checkPermissions from './guards/checkPermissions';
import Routes from '../components/pages/Routes/index';
import AddRoute from '../components/pages/AddRoute/index';
import EditRoute from '../components/pages/EditRoute/index';
import MapPage from '../components/pages/Map/index'

export interface NavigationRoute extends GuardedRouteProps {
    path: string;
    name?: string;
    sideBar?: boolean;
    icon?: any;
    permissions?: Array<string>;
    redirectsFrom?: string;
    children?: Array<NavigationRoute>;
}

export const routeList: Array<NavigationRoute> = [
    {
        exact: true,
        path: '/login',
        component: Login,
        meta: {
            guest: true,
            auth: false,
            head: {
                title: 'Inloggen'
            }
        },
        redirectsFrom: '/'
    },
    {
        exact: true,
        path: '/add-route',
        component: AddRoute,
        meta: {
            guest: false,
            auth: true,
            head: {
                title: 'Dashboard'
            }
        },
        icon: faPlus,
        sideBar: true,
        name: 'Route toevoegen'
    },
    {
        exact: true,
        path: '/edit-route',
        component: EditRoute,
        meta: {
            guest: false,
            auth: true,
            head: {
                title: 'Dashboard'
            }
        },
        sideBar: false,
        name: 'Route aanpassen`'
    },
    {
        exact: true,
        path: '/home',
        component: Routes,
        meta: {
            guest: false,
            auth: true,
            head: {
                title: 'Dashboard'
            }
        },
        icon: faRoute,
        sideBar: true,
        name: 'Routes'
    },
    {
        exact: true,
        path: '/map',
        component: MapPage,
        meta: {
            guest: false,
            auth: true,
            head: {
                title: 'Dashboard'
            }
        },
        icon: faHome,
        sideBar: false,
        name: 'Map'
    },
    {
        exact: true,
        path: '/logout',
        component: LogoutPage,
        icon: faPowerOff,
        sideBar: true,
        name: 'Uitloggen'
    }
];

const renderRoute = (route: NavigationRoute, path?: string): React.ReactNode => (
    <GuardedRoute
        exact={route.exact}
        path={path || route.path}
        component={(route as NavigationRoute).component}
        meta={{
            ...(route as NavigationRoute).meta,
            permissions: (route as NavigationRoute).permissions
        }}
        render={route.children?.length ? (({ match: { url } }) => (
            route.children?.map((subRoute) => renderRoute(subRoute, url + subRoute.path))
        )) : undefined}
    />
);

export default (
    <GuardProvider guards={[auth, checkPermissions, modifyHead, clearNotifications]}>
        <Switch>
            {routeList.map((route) => (route.redirectsFrom ? (
                <Redirect
                    exact
                    key={route.path + route.redirectsFrom}
                    to={route.path}
                    from={route.redirectsFrom}
                />
            ) : null))}
            {routeList.map((route) => renderRoute(route))}
        </Switch>
    </GuardProvider>
);


/*
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MapPage from '../components/pages/Map/index';
import Routes from '../components/pages/Routes/index';
import AddRoute from '../components/pages/AddRoute/index';
import EditRoute from '../components/pages/EditRoute/index';
import RouteGroups from '../components/pages/RouteGroups/index';
import AddRouteGroup from '../components/pages/AddRouteGroup/index';
import EditRouteGroup from '../components/pages/EditRouteGroup/index';

export default (
    <Switch>
        <Route exact path="/map" component={MapPage} />
        <Route exact path="/" component={Routes} />
        <Route exact path="/add-route" component={AddRoute} />
        <Route exact path="/edit-route" component={EditRoute} />
        <Route exact path="/route-groups" component={RouteGroups} />
        <Route exact path="/add-route-group" component={AddRouteGroup} />
        <Route exact path="/edit-route-group" component={EditRouteGroup} />
    </Switch>
)*/
