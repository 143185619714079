export default {
    google_api_key: 'AIzaSyCu8B-qNIg6UM2YRoyqgY5fG6_ZC_ycD-Y',
    app_name: 'sight_seeing',
    grant_type: 'password',
    client_id: '2',
    secret_key: 'RzRaP4faORpwEJ7QTsdTvNwM5U0Y8RCKz5be1l0Q',
    base_url: 'https://backend.nieuw-energiecontract.nl',
    api: {
        urls: {
            oauth: 'oauth/token',
            user: {
                info: 'api/v2/userinfo',
                permissions: 'api/v2/user/permissions'
            },
            configuration: 'api/v2/frontend/{name}/configurations',
            route: {
                splitRoute: 'api/v2/splitroute'
            }
        }
    },
    logging: {
        rollbar: {
            accessToken: '',
            captureUncaught: true,
            captureUnhandledRejections: true
        }
    }
}
