import React from 'react';
import Page from '../Page';
import AddRouteForm from '../../shared/forms/AddRouteForm';
import { Col } from 'react-bootstrap';

class AddRoute extends React.Component {

    render () {
        return(
            <Page title="Route toevoegen">
                <Col className="h-100" sm={12} >
                    <div className="add-route-page mt-5 h-100" >
                        <AddRouteForm/>
                    </div>
                </Col>
            </Page>
        )
    }
}

export default AddRoute;