import { Container as InversifyContainer } from 'inversify';
import { AuthStore } from './stores/auth/AuthStore';
import { AuthStoreContract } from './stores/auth/AuthStoreContract';
import { ResponseService } from './services/connections/rest/response/ResponseService';
import { ApiService } from './services/connections/rest/ApiService';
import { NotificationStoreContract } from './stores/notifications/NotificationStoreContract';
import { NotificationStore } from './stores/notifications/NotificationStore';
import { CacheStore } from './stores/storage/caching/CacheStore';
import { GlobalStoreContract } from './stores/global/GlobalStoreContract';
import LogService from './services/logging/LogService';
import { GlobalStore } from './stores/global/GlobalStore';
import { Application } from './Application';
import { PermissionServiceContract } from './services/permissions/PermissionServiceContract';
import { PermissionService } from './services/permissions/PermissionService';
import {RouteStoreContract} from "./stores/routes/RouteStoreContract";
import {RouteStore} from "./stores/routes/RouteStore";

const Container = new InversifyContainer();

Container.bind<ApiService>('api').to(ApiService);
Container.bind<ResponseService<any, any>>('responseService').to(ResponseService);
Container.bind<LogService>('logging').to(LogService);
Container.bind<PermissionServiceContract>('permissions').to(PermissionService);
Container.bind<Application>('app').to(Application);
// stores
Container.bind<AuthStoreContract>('auth').to(AuthStore).inSingletonScope();
Container.bind<NotificationStoreContract>('notifications').to(NotificationStore).inSingletonScope();
Container.bind<GlobalStoreContract>('global').to(GlobalStore).inSingletonScope();
Container.bind<RouteStoreContract>('routes').to(RouteStore).inSingletonScope()
Container.bind<CacheStore>('cache').to(CacheStore);
export default Container;
