import React from 'react';
import history from '../../../app/utils/history';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Container from '../../../app/Container';
import {toJS} from "mobx";

@inject('routes')
@observer
class GoogleMap extends React.Component {
    directionsRenderers = [];
    directionsService;

    state;

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            directionsRefs: [
            ],
            routesArray: [],
            map: null,
            directionsService: null,
            directionsRenderer: null,
            geocoder: new window.google.maps.Geocoder(),
            showDirections: true
        }
    }
    googleMapRef = React.createRef();

    componentDidMount () {
        const {
            routes : {
                Routes: {
                    selectedRoute
                }
            }
        } = this.props;
        if(!selectedRoute){
            history.push('/')
            return;
        } else {
            this.setState({
                ...this.state,
                dataLoaded: true,
                travelModes: selectedRoute.travelModes
            })
            this.createGoogleMap();
        }
    }

    OrganiseRoute = () => {
        const {
            routes: {
                Routes: {
                    selectedRoute : {
                        routeParams,
                        end,
                        start
                    }
                }
            }
        } = this.props
        const splitRoutes = () => {
            let i = 0;
            let routes = [];
            let currentRoute = 0;
            let itemsPerRoute = 24;
            if(routeParams.length === 0) routes.push([]);
            routeParams.forEach( item => {
                if(!routes[currentRoute]) {
                    routes[currentRoute] = [];
                }
                routes[currentRoute].push(item);

                i++
                if(i === itemsPerRoute || item.isStop) {
                    i = 0;
                    itemsPerRoute = 23;
                    currentRoute++;

                    if(!routes[currentRoute]) {
                        routes[currentRoute] = [];
                    }

                    routes[currentRoute].push(routes[currentRoute - 1][routes[currentRoute - 1].length - 1])
                }
            });
            routes[0].unshift(start);
            routes[routes.length - 1].push(end);
            return routes;
        }
        return splitRoutes();
    }

    checkForMultiRoute = () => {
        let routes = this.OrganiseRoute();

        routes.forEach(() => {
            this.state.directionsRefs.push(React.createRef());
        })

        let i = 0;
        // create de  a renderer for each route
        const polyLineColors = [
            'red',
            'blue',
            'green',
            'purple',
            'orange',
            'pink',
            'yellow',
            'maroon',
            'crimson',
            'white',
            'grey',
            'teal'
        ]

        routes.forEach(() => {
            const newDirectionsRenderer = new window.google.maps.DirectionsRenderer({
                polylineOptions: {
                    strokeColor: polyLineColors[i],
                    strokeWeight: 6
                }
            });

            newDirectionsRenderer.setMap(this.state.map);
            newDirectionsRenderer.setPanel(this.state.directionsRefs[i].current);
            this.directionsRenderers.push(newDirectionsRenderer)
            i++
        })
        //create the new routes from one big route

        this.setState({
            routesArray: routes
        }, () => this.calculateAndDisplayRoute())
        return;
    }

    createGoogleMap() {
        this.directionsService = new window.google.maps.DirectionsService();
        this.setState({
            map : new window.google.maps.Map(this.googleMapRef.current, {
                zoom: 14,
                center: {
                    lat: 52.9596311,
                    lng: 5.9217004
                },
                disableDefaultUI: true
            })
        }, () => this.checkForMultiRoute())

    }

    drawFlightPath = async (route) => {
        const {
            geocoder
        } = this.state;
        route = [...route]
        let done = false;
        const startGeo = {};
        const endGeo = {};
        const waypointsGeo = {};

        console.log(route)
        const getGeoLocations = () => {
            let flightCoordinates = [];

            return new Promise((resolve) => {
                // get geolocations
                geocoder.geocode(route[0].placeId ? {'placeId': route[0].placeId} : {'address': route[0].address}, (results, status) => {
                    if (status == 'OK') {
                        let geo = results[0].geometry.location;
                        console.log('first')
                        flightCoordinates.push({
                            lat : geo.lat(),
                            lng : geo.lng(),
                        })
                        waypointCoords();
                    }
                })
                const waypointCoords = () => {
                    route.map((waypoint, index) => {
                        if(index === 0) return route;
                        if(index === route.length - 1){
                            endCoords()
                            return;
                        }
                        geocoder.geocode(waypoint.placeId ? {'placeId': waypoint.placeId} : {'address': waypoint.address}, (results, status) => {
                            if (status == 'OK') {
                                let geo = results[0].geometry.location;
                                console.log('waypoint')
                                flightCoordinates.push({
                                    lat : geo.lat(),
                                    lng : geo.lng(),
                                })
                            }
                        })
                    })
                }
                const endCoords = () => {
                    geocoder.geocode(route[route.length - 1].placeId ? {'placeId': route[route.length - 1].placeId} : {'address': route[route.length - 1].address}, (results, status) => {
                        if (status == 'OK') {
                            let geo = results[0].geometry.location;
                            console.log('last')
                            flightCoordinates.push({
                                lat : geo.lat(),
                                lng : geo.lng(),
                            })
                            resolve(flightCoordinates);
                        }
                    })
                }
            })
        }
        const testPath = [
            {lat: 52.9605613, lng: 5.920521700000001},
            {lat: 52.51853699999999, lng: 5.471422},
            {lat: 52.3666969, lng: 4.8945398}
        ]
        const coords = await getGeoLocations();

        console.log('coords', coords.length, coords);
        const flightPath = new window.google.maps.Polyline({
            path: coords,
            geodesic: true,
            strokeColor: "#41b4ec",
            strokeOpacity: 1.0,
            strokeWeight: 4
        })

        console.log(flightPath.getPath())
        // this should draw the map
        flightPath.setMap(this.state.map);

    }

    calculateAndDisplayRoute() {
        const {
            routesArray,
            travelModes
        } = this.state;
        let i = 0;
        routesArray.forEach((route) => {
            route = [...route]
            if(travelModes[i] === "FLYING"){
                this.drawFlightPath(route)
            } else {

                let directionsRenderer = this.directionsRenderers[i];
                const start = route[0].placeId ? {'placeId': route[0].placeId} : route[0].address;
                const end = route[route.length - 1].placeId ? {'placeId': route[route.length - 1].placeId} : route[route.length - 1].address;
                route.pop();
                route.shift();
                const waypoints = route.map(waypoint => {
                    return {
                        location: waypoint.placeId ? {'placeId': waypoint.placeId} : waypoint.address,
                        stopover: true
                    }
                });
                const routeWaypoints = [...waypoints];
                this.directionsService.route({
                    origin: start,
                    destination: end,
                    waypoints: routeWaypoints,
                    optimizeWaypoints: true,
                    travelMode: travelModes[i]
                }, (response, status) => {
                    if (status === 'OK') {
                        directionsRenderer.setDirections(response);
                        console.log(response);
                    } else {
                        if (i === routesArray.length - 1) {
                            window.alert('Er ging iet mis door ' + status + '      Vervoers keuze werkt niet volledig bij grote routes, Bij twijfel kies auto.');
                        }
                    }
                })
            }
            i++;
        })

    }

    toggleDirections = () => {
        this.setState({
            showDirections: !this.state.showDirections
        })
    }

    getDiretionStyling = () => {
        const {
            showDirections
        } = this.state

        if(showDirections) {
            return 'google-map-directions'
        }
        return (
            'google-map-directions d-none'
        )
    }

    directionsHolders = () => {
        let routes = this.OrganiseRoute();

        routes.forEach(() => {
            this.state.directionsRefs.push(React.createRef());
        })

        return (
            this.state.directionsRefs.map( (ref) => {
                return (
                    <div
                        ref={ref}
                    />
                )
            })
        )
    }

    render() {
        const {
            dataLoaded
        } = this.state;

        return (
            <div className="map-container">
                <div
                    id="google-directions"
                    className={this.getDiretionStyling()}
                >
                    {dataLoaded? this.directionsHolders(): null}
                </div>
                <div
                    id="google-map"
                    ref={this.googleMapRef}
                    className="google-map"
                />
                <button
                    onClick={this.toggleDirections}
                    className='show-directions'
                >
                    beschrijving
                </button>
            </div>
            
        )
    }
}

GoogleMap.propTypes = {
    getRoutes: PropTypes.func.isRequired,
    routeStore: PropTypes.shape({
        selectedRoute: PropTypes.oneOfType([
            PropTypes.object.isRequired,
            PropTypes.oneOf([null]).isRequired
        ])
    }),
}

export default GoogleMap;

