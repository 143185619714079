/**
 * Reducer responsible for handling the actions performed when mutating routes
 */

import { types } from '../actions/types';

const isRoutesStateSet = () => {
    return {
        selectedRoute: null,
        routes: []
    };
};

const initialState = isRoutesStateSet();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function RoutesReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case types.ROUTE_POST:
            newState = {
                ...state,
                routes: [
                    ...state.routes, 
                    {
                        id: action.payload.id,
                        name: action.payload.name,
                        start: action.payload.start,
                        end: action.payload.end,
                        routeParams: action.payload.routeParams
                    }
                ]
            };
            return newState;

        case types.ROUTE_GET:
            newState = {
                ...state,
                routes: action.payload
            }
            return newState;

        case types.ROUTE_DELETE:
            // Remove 
            newState = {
                ...state,
                routes: state.routes.filter(route => route.id !== action.payload)
            }
            return newState;

        case types.ROUTE_PUT:
            const updateRoutes = () => {
                let id = action.payload.id;

                return state.routes.map((route) => {
                    if (route.id !== id) {
                        return route
                    }
                    return action.payload
                })
            }

            newState = {
                ...state,
                routes: updateRoutes()
            };


            return newState;
            
        case types.ROUTE_SELECT:
            return {
                selectedRoute: action.payload,
                routes: [
                    ...state.routes
                ]
            }
        default:
            return state;
    }
}
