import Container from '../../../app/Container';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionServiceContract } from '../../../app/services/permissions/PermissionServiceContract';
import { NavigationRoute, routeList } from '../../../app/routes';

class SideNavLinks extends Component<{}, {}> {
    private renderItem(route: NavigationRoute, path: string): React.ReactNode {
        if (!route.sideBar) {
            return null;
        }

        return (
            <div className="sidebar-nav-item">
                <NavLink
                    className="sidebar-nav-item-link"
                    key={route.name}
                    to={path}
                >
                    <div className="sidebar-nav-icon">
                        {route.icon && (
                            <FontAwesomeIcon className="icon-gradient" icon={route.icon} />
                        )}
                    </div>
                    <span className="sidebar-nav-item-name">{route.name}</span>
                </NavLink>
                {route.children?.length && (
                    <div className="sidebar-nav-item-sub">
                        {route.children.map((subRoute) => this.renderItem(subRoute, route.path + subRoute.path))}
                    </div>
                )}
            </div>
        );
    }

    private conditionallyRenderLink(route: NavigationRoute): React.ReactNode {
        if (!route.sideBar) {
            return null;
        }

        const permissionsService = Container.get<PermissionServiceContract>('permissions');
        if (route.permissions && route.permissions.length) {
            let canGo = true;

            route.permissions.forEach((permission: string) => {
                if (!permissionsService.hasPermission(permission)) {
                    canGo = false;
                }
            });

            if (!canGo) {
                return null;
            }
        }

        return this.renderItem(route, route.path);
    }

    public render(): React.ReactNode {
        return routeList.map((route: NavigationRoute) => (
            this.conditionallyRenderLink(route)
        ));
    }
}

export default SideNavLinks;
