import axios from 'axios';
import config from '../../../config';
import { inject, injectable } from 'inversify';
import { observable, action } from 'mobx';
import { RouteStoreContract } from './RouteStoreContract'
import { LoadingStore } from '../loading/LoadingStore';
import { LoadingState } from '../loading/LoadingState';
// @ts-ignore
import RouteRepository from '../../database/repositories/route-repository';
import {types} from "../../actions/types";
import {ApiService} from "../../services/connections/rest/ApiService";

interface routesInterface {
    selectedRoute: {} | null;
    routes: [];
}

@injectable()
export class RouteStore extends LoadingStore implements RouteStoreContract {
    @observable
    Routes: routesInterface = { selectedRoute: null, routes: [] };

    private api: ApiService;

    public constructor(
        @inject('api') api: ApiService,
    ) {
        super();
        this.api = api;
    }

    public async get(filterParams: { filterById: any }): Promise<void> {
        try {
            const routes = await RouteRepository.getRoute(filterParams);
            this.Routes = {
                ...this.Routes,
                routes
            };
            // @ts-ignore

        } catch(event) {
            console.log('something went wrong while Getting route from database');
        }
    }

    public async select(id: string): Promise<void> {
        try {
            const route = await RouteRepository.getRoute({filterById: id});
            this.Routes = {
                ...this.Routes,
                selectedRoute: route
            }
        } catch {
            console.log('something went wrong while Selecting route');
        }
    }

    public async post(route: unknown): Promise<void> {
        try {
            // @ts-ignore
            const response = await RouteRepository.postRoute(route);
        } catch(event) {
            console.log('something went wrong while Posting route to database');
        }
    }

    public async put(route: unknown): Promise<void> {
        try {
            // @ts-ignore
            const response = await RouteRepository.putRoute(route);
        } catch(event) {
            console.log('something went wrong while Posting route to database');
        }
    }

    public async delete(id: string): Promise<void> {
        try {
            await RouteRepository.deleteRoute(id)
        }catch {
            console.log('something went wrong while Deleting route from database');
        }
    }

    public async split(route: unknown): Promise<void> {
        this.setLoadingState('get-split-route', LoadingState.LOADING);

        const response = await axios({
            url: `${config.base_url}/${config.api.urls.route.splitRoute}`,
            method: 'GET',
            headers: this.api.getAccessHeaders()
        })
        console.log(route);
        this.setLoadingState('get-sale-pdf', LoadingState.LOADED);
    }
}
