import React from 'react';
import Page from '../Page'
import GoogleMap from '../../shared/map/google-maps';
class MapPage extends React.Component {


    render () {
        return(
            <Page>
                <GoogleMap/>
            </Page>
        )
    }
}

export default MapPage;