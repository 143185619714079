class Str {
    public static ucFirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public static studly(str: string): string {
        return str
            .split(/[-_]/g)
            .map((word: string) => Str.ucFirst(word))
            .join('');
    }
}

export default Str;
