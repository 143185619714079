import { GuardFunction } from 'react-router-guards';
import Container from '../Container';
import { AuthStoreContract } from '../stores/auth/AuthStoreContract';

export const AUTH_REDIRECT = '/home';
export const GUEST_REDIRECT = '/logout';

const whatAmI = (): string => {
    if (Container.get<AuthStoreContract>('auth').user) {
        return 'auth';
    }

    return 'guest';
};

const auth: GuardFunction = (to, _from, next) => {
    if (
        (to.meta.guest && whatAmI() === 'guest')
        || (to.meta.auth && whatAmI() === 'auth')
    ) {
        return next();
    }

    if (to.meta.guest && whatAmI() === 'auth') {
        return next.redirect(AUTH_REDIRECT);
    }

    return next.redirect(GUEST_REDIRECT);
};

export default auth;
