import * as Axios from 'axios';
import { ErrorResponse } from './ErrorResponse';

export class Response<T> {
    private axiosResponse?: Axios.AxiosResponse<T>;

    private potentialBody?: T;

    public constructor(
        axiosResponse?: Axios.AxiosResponse<T>,
        body?: T
    ) {
        this.axiosResponse = axiosResponse;

        if (!this.axiosResponse) {
            this.potentialBody = body;
        }
    }

    public getStatus(): number | null {
        if (this.axiosResponse) {
            return this.axiosResponse.status;
        }

        return null;
    }

    public getData(): T | null {
        if (this.axiosResponse) {
            return this.axiosResponse.data;
        }

        if (this.potentialBody) {
            return this.potentialBody;
        }

        return null;
    }

    public static make(body: ErrorResponse): Response<ErrorResponse> {
        return new Response(
            undefined,
            body
        );
    }
}
