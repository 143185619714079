import { StoreInterface } from './StoreInterface';
import Container from '../Container';
import { AuthStoreContract } from './auth/AuthStoreContract';
import { NotificationStoreContract } from './notifications/NotificationStoreContract';
import { GlobalStoreContract } from './global/GlobalStoreContract';
import { Application } from '../Application';
import {RouteStoreContract} from "./routes/RouteStoreContract";


export class StoresRoot {
    private static instance: StoresRoot;

    private stores: Record<string, StoreInterface> = {
        auth: Container.get<AuthStoreContract>('auth'),
        global: Container.get<GlobalStoreContract>('global'),
        notifications: Container.get<NotificationStoreContract>('notifications'),
        application: Container.get<Application>('app'),
        routes: Container.get<RouteStoreContract>('routes')
    };

    public getStores(): Record<string, StoreInterface> {
        return this.stores;
    }

    public static getInstance(): StoresRoot {
        if (!StoresRoot.instance) {
            StoresRoot.instance = new StoresRoot();
        }

        return StoresRoot.instance;
    }
}
