import db from '../db'
import uuid from '../../utils/uuid';
import SightRepository from './sight-repository';
import {toJS} from "mobx";

class RouteRepository {

    /**
     * @param {object} filterParams - Objcect that stores the filter parameters
     * @param {string} filterParams.filterById - Id use to filter
     * @returns {object|array}  RouteObject containing all data or array containing routeObjects
     */
    static async getRoute(filterParams) {
        if(filterParams.filterById){

            return await db.routes.where({id: filterParams.filterById}).first( async route => {
                let routeObject = {};
                if(route){
                    routeObject.name = route.name;
                    routeObject.id = route.id;
                    routeObject.description = route.description;
                    routeObject.description_link = route.description_link;
                    routeObject.travelModes = route.travelModes;
                    routeObject.start = await SightRepository.getSingleSightById(route.start);
                    routeObject.end = await SightRepository.getSingleSightById(route.end);
                    routeObject.routeParams = await SightRepository.getSightsById(route.sightIds); 
                    return routeObject;
                }
                return null
            })
        }
        const routesArray = await db.table('routes').toArray();

        const RoutesObjectArray = await Promise.all(routesArray.map( async route => {
            const getValues = async (route) => {
                let routeObject = {};
                routeObject.name = route.name;
                routeObject.id = route.id;
                routeObject.description = route.description;
                routeObject.description_link = route.description_link;
                routeObject.travelModes = route.travelModes;
                routeObject.start = await SightRepository.getSingleSightById(route.start);
                routeObject.end = await SightRepository.getSingleSightById(route.end);
                routeObject.routeParams = await SightRepository.getSightsById(route.sightIds);
                return routeObject;
            }
            return await getValues(route)
        }))
        return RoutesObjectArray;

    }   

    /**
     * @param {object} route - Route object where all data is stored
     * @param {string} route.name - Name of the route
     * @param {string} route.id - id of the route
     * @param {string} route.end- end of the route
     * @param {string} route.start - start of the route
     * @param {array} route.routeParams - array of object with location stored as string
     */
    static async postRoute(route) {
        const id = uuid();
        const name = route.name;
        let start = '';
        let end = '';
        const sightsArray = route.routeParams;
        const description = route.description;
        const description_link = route.description_link;
        const travelModes = route.travelModes;
        const sightIds = [];

        const postStart = () => {
            const {
                placeId,
                label,
                address,
                isStop
            } = route.start;

            const id = uuid();
            SightRepository.postSight(id, label, '', '', placeId, address,isStop)
            start = id;
        }

        const postEnd = () => {
            const {
                placeId,
                label,
                address,
                isStop
            } = route.end;

            const id = uuid();
            SightRepository.postSight(id, label, '', '', placeId, address,isStop)
            end = id;
        }

        postStart();
        postEnd();
        sightsArray.map( sight => {
            const {
                description,
                description_link,
                placeId,
                label,
                address,
                isStop
            } = sight;

            const id = uuid();
            SightRepository.postSight(id, label, description, description_link, placeId, address, isStop)
            sightIds.push(id);
            return '';
        })

        db.routes.add({
            id,
            name,
            start,
            end,
            sightIds,
            description,
            description_link,
            travelModes
        })
    }

    /**
     * @param {object} route - Route object where all data is stored
     * @param {string} route.name - Name of the route
     * @param {string} route.id - id of the route
     * @param {string} route.end- end of the route
     * @param {string} route.start - start of the route
     * @param {array} route.routeParams - array of object with location stored as string
     */
    static async putRoute(route) {
        const id = route.id;
        const name = route.name;
        let start = '';
        let end = '';
        const description = route.description;
        const description_link = route.description_link;
        const sightsArray = route.routeParams;
        const travelModes = route.travelModes;
        const sightIds = [];

        const deleteOldSights = async () =>  {
            await db.routes.where({id: id}).first( async route => {
                await SightRepository.deleteSight(route.start);
                await SightRepository.deleteSight(route.end);
                route.sightIds.map( async sightId => {
                    await SightRepository.deleteSight(sightId)
                })
            })
        }

        // clean out old  route sightsIds array and corresponding sights from database
        await deleteOldSights();

        const postStart = () => {
            const {
                placeId,
                label,
                address,
                isStop
            } = route.start;

            const id = uuid();
            SightRepository.postSight(id, label, '', '', placeId, address,isStop)
            start = id;
        }

        const postEnd = () => {
            const {
                placeId,
                label,
                address,
                isStop
            } = route.end;

            const id = uuid();
            SightRepository.postSight(id, label, '', '', placeId, address,isStop)
            end = id;
        }

        postStart();
        postEnd();
        sightsArray.map( sight => {
            const {
                description,
                description_link,
                placeId,
                label,
                address,
                isStop
            } = sight;

            const id = uuid();
            SightRepository.postSight(id, label, description, description_link, placeId, address, isStop)
            sightIds.push(id);
            return '';
        })

        db.routes.put({
            id,
            name,
            start,
            end,
            sightIds,
            description,
            description_link,
            travelModes
        })


    }

    /**
     * @param {string} route.id - id of the route
     */
    static async deleteRoute(id) {
        const deleteStartAndEnd = await db.routes.where({id: id}).first( async route => {
            await SightRepository.deleteSight(route.start);
            await SightRepository.deleteSight(route.end);
        })
         //get the route by id to get to the route sightIds
        const sightIds = await db.routes.where({id: id}).first( async route => {
            return route.sightIds
        })
        //map the sightIds array and promise.all delete the sights
        const deleteSights = await Promise.all(sightIds.map(async sightId => {
            await SightRepository.deleteSight(sightId)
        }))

        db.routes.delete(id);
        deleteStartAndEnd();
        deleteSights();
        //after the sights are deleted then delete the route itself
    }
}

export default RouteRepository;