import { injectable } from 'inversify';
import { CacheStoreContract } from './CacheStoreContract';

@injectable()
export class CacheStore implements CacheStoreContract {
    public async clear(): Promise<void> {
        if ('caches' in window) {
            const keyList = await window.caches.keys();

            keyList.forEach((key: string) => {
                window.caches.delete(key);
            });
        }
    }
}
