export const types = {
    NAV_TOGGLE: 'NAV_TOGGLE',

    //Routes
    ROUTE_PUT: 'ROUTE_PUT',
    ROUTE_GET: 'ROUTE_GET',
    ROUTE_DELETE: 'ROUTE_DELETE',
    ROUTE_POST: 'ROUTE_POST',
    ROUTE_SELECT: 'ROUTE_SELECT',

    //Route Groups
    ROUTE_GROUP_PUT: 'ROUTE_GROUP_PUT',
    ROUTE_GROUP_GET: 'ROUTE_GROUP_GET',
    ROUTE_GROUP_DELETE: 'ROUTE_GROUP_DELETE',
    ROUTE_GROUP_POST: 'ROUTE_GROUP_POST',
    ROUTE_GROUP_SELECT: 'ROUTE_GROUP_SELECT'
}