import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import history from '../../app/utils/history';
import Container from '../../app/Container';
import LogService from '../../app/services/logging/LogService';

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<RouteComponentProps, ErrorBoundaryState> {
    state: Readonly<ErrorBoundaryState> = {
        hasError: false
    }

    constructor(props: RouteComponentProps) {
        super(props);

        history.listen(() => {
            const {
                hasError
            } = this.state;

            if (hasError) {
                this.setState({
                    hasError: false
                });
            }
        });
    }

    static getDerivedStateFromError() {
        return {
            hasError: true
        };
    }

    componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
        Container.get<LogService>('logging').error(error);
    }

    render(): React.ReactNode {
        const {
            hasError
        } = this.state;

        const {
            children
        } = this.props;

        if (hasError) {
            return <Redirect to="/whoops" />;
        }

        return children;
    }
}

export default withRouter(ErrorBoundary);
