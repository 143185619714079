import React from 'react';
import { inject } from 'mobx-react';
import { AuthStoreContract } from '../../../app/stores/auth/AuthStoreContract';
import { Modal, Container, Button} from 'react-bootstrap'
import history from "../../../app/utils/history";

interface LogoutPageProps {
    auth?: AuthStoreContract;
}

@inject('auth')
class LogoutPage extends React.Component<LogoutPageProps, {}> {

    logout = () => {
        const {
            auth
        } = this.props;

        auth!.logout();
    }
    public render(): React.ReactNode {
        return (
            <Container fluid className="logout-page">
                <Modal centered show={true} backdrop={false} className="logout-modal">
                    <Modal.Header>
                        <Modal.Title className="text-center w-100">Weet u zeker dat u wil uitloggen?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button className="logout-btn" size="sm" variant="success" onClick={() => {history.push('/home')}}>
                            terug
                        </Button>
                        <Button className="logout-btn" size="sm" variant="danger" onClick={this.logout}>
                            Uitloggen
                        </Button>
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

export default LogoutPage;
