import React from 'react';
import { inject, observer } from 'mobx-react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import FormValidator from '../../../app/utils/validation/FormValidator';
import { AuthStoreContract } from '../../../app/stores/auth/AuthStoreContract';
import { NotificationStoreContract } from '../../../app/stores/notifications/NotificationStoreContract';
import ChangableInputComponent from '../../shared/ChangableInputComponent';
import { Notification } from '../../../app/stores/notifications/Notification';
import { LoadingStoreContract } from '../../../app/stores/loading/LoadingStoreContract';
import { LoadingState } from '../../../app/stores/loading/LoadingState';
// import LoadingIndicator from '../../shared/loading/loading-indicator';
import Button from '../../shared/button';
import { Variations } from '../../../app/utils/interfaces/variations';

interface LoginProps {
    auth?: AuthStoreContract & LoadingStoreContract;
    notifications?: NotificationStoreContract;
}

interface LoginState {
    email: string;
    password: string;
    formValid: boolean;
}

@inject('auth', 'notifications')
@observer
class Login extends ChangableInputComponent<LoginProps, LoginState> {
    public state: Readonly<LoginState> = {
        email: '',
        password: '',
        formValid: true
    };

    private validator?: FormValidator;

    private formRef: React.RefObject<HTMLFormElement>;

    constructor(props: LoginProps) {
        super(props);

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.validator = new FormValidator(this.formRef);
    }

    handleSubmit = (event: React.FormEvent) => {
        const {
            auth,
            notifications
        } = this.props;

        event.preventDefault();

        this.setState({
            formValid: true
        }, () => {
            this.validator!.clear();
            notifications!.clear();

            if (this.validator!.validate()) {
                auth!.login(this.state.email, this.state.password);
            } else {
                this.setState({
                    formValid: false
                });
            }
        });
    };

    render() {
        const {
            formValid
        } = this.state;

        const {
            auth,
            notifications
        } = this.props;

        return (
            <div className="login-page">
                <div className="login-card">
                    <Container fluid className="vh-100">
                        <div className="row justify-content-center h-100">

                            <div className="col-6 flex align-self-center align-items-center justify-content-center text-center flex-column">
                                <h1 className="mb-3 app-title">Sight Seeing</h1>
                                <h2 className="mb-5 pb-5 app-sub-title">Travel route generator</h2>
                                <h3 className="mb-4 helper-text">Login om verder te gaan</h3>
                                {notifications!.all().map((notification: Notification) => (
                                    <Alert
                                        key={notification.id}
                                        // @ts-ignore
                                        variant={notification.variation}
                                    >
                                        {notification.message}
                                    </Alert>
                                ))}
                                <form
                                    className="login-form"
                                    ref={this.formRef}
                                    method="POST"
                                    onSubmit={this.handleSubmit}
                                >
                                    <Form.Group>
                                        <Form.Control
                                            data-validate="required"
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleInputChange(event);
                                            }}
                                            isInvalid={!formValid && this.validator!.has('email')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {this.validator?.first('email')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            data-validate="required"
                                            type="password"
                                            name="password"
                                            placeholder="Wachtwoord"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleInputChange(event);
                                            }}
                                            isInvalid={!formValid && this.validator!.has('email')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {this.validator?.first('password')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Button
                                                type="submit"
                                                variation={Variations.LIGHT}
                                                loading={auth!.getLoadingState('login') === LoadingState.LOADING}
                                                block
                                            >
                                                Inloggen
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Login;
