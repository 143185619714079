import { GuardFunction } from 'react-router-guards';
import Container from '../Container';
import { Application } from '../Application';

const app = Container.get<Application>('app');

const createTitle = (title: string): string => {
    const appName = app.configuration?.application_name;

    return `${appName || ''}: ${title}`;
};

const modifyHead: GuardFunction = (to, _from, next) => {
    const meta = to.meta?.head?.meta;
    const title = to.meta?.head?.title;

    if (title) {
        document.title = createTitle(title);
    }

    Array.from(document.querySelectorAll('[data-react-router-controlled]')).map((el) => el.parentNode?.removeChild(el));

    if (!meta) {
        return next();
    }

    meta.map((metaTag: Record<string, string>) => {
        const tag = document.createElement('meta');

        Object.keys(metaTag).forEach((attribute: string) => {
            tag.setAttribute(attribute, metaTag[attribute]);
        });

        tag.setAttribute('data-react-router-controlled', '');

        return tag;
    })
        .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));

    return next();
};

export default modifyHead;
