import React from 'react';

export default class AnimatedInput extends React.Component {
    render() {
        const {
            as,
            rows,
            type,
            name,
            value,
            placeholder,
            onChange,
            reference,
            required,
            label
        } = this.props;
        if(as === 'textarea') {
            return (
                <div className="animated-input-wrapper mb-1 mt-5">
                    <textarea
                        className="input-text"
                        rows={rows}
                        id = {name}
                        type = {type}
                        name = {name}
                        value = {value}
                        placeholder = {placeholder}
                        onChange = {onChange}
                        required = {required}
                        ref = {reference}
                        pattern=".*\S.*"
                    />
                    <label className={"input-label"} htmlFor={name}>{label}</label>
                </div>
            )
        }
        return (
            <div className="animated-input-wrapper mb-1 mt-5">
                <input
                    className="input-text"
                    id = {name}
                    type = {type}
                    name = {name}
                    value = {value}
                    placeholder = {placeholder}
                    onChange = {onChange}
                    required = {required}
                    ref = {reference}
                    pattern=".*\S.*"
                />
                <label className={"input-label"} htmlFor={name}>{label}</label>
            </div>
        )
    }
}
