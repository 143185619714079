import { autorun, observable } from 'mobx';
import { injectable } from 'inversify';
import { NotificationStoreContract } from './NotificationStoreContract';
import { Notification } from './Notification';
import generateGuid from '../../utils/helpers/generateGuid';

@injectable()
export class NotificationStore implements NotificationStoreContract {
    @observable
    private _notifications: Array<Notification> = [];

    public all(): Array<Notification> {
        return this._notifications;
    }

    public addNotification(notification: Omit<Notification, 'id'>): void {
        const id = generateGuid();
        const notificationWithId: Notification = {
            ...notification,
            id
        };

        this._notifications.push(notificationWithId);

        if (notification.autoClose) {
            autorun(() => {
                this.removeNotification(id);
            }, { delay: 5000 });
        }
    }

    public removeNotification(id: string): void {
        this._notifications = this._notifications.filter((n: Notification) => n.id !== id);
    }

    public clear(): void {
        this._notifications = [];
    }
}
