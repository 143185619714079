import React from 'react';
import PropTypes from 'prop-types';
import AnimatedInput from "../input/AnimatedInput";
import {Form} from "react-bootstrap";
class PlacesAutocompleteInput extends React.Component{
    state;
    autoComplete;

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            query: null,
            setQuery: null
        }
    }
    searchInputRef = React.createRef();

    componentDidMount () {
        const {
            autoFocus
        } = this.props

        this.createGooglePlacesAutocomplete()
        if(this.searchInputRef && autoFocus){
            this.searchInputRef.current.focus()
        }
    }

    createGooglePlacesAutocomplete() {
        this.autoComplete = new window.google.maps.places.Autocomplete(
            this.searchInputRef.current,
            { types: [] }
        );
        this.autoComplete.setFields(["formatted_address", "place_id", "name"]);
        this.autoComplete.addListener("place_changed", () =>
            this.handlePlaceSelect()
        );
    }

    async handlePlaceSelect() {
        const {
            name,
            addWaypoint
        } = this.props;

        const placeObject = this.autoComplete.getPlace(); // get place from google api
        const address = placeObject.formatted_address;
        const placeId = placeObject.place_id;
        const label = placeObject.name;
        console.log(placeObject);

        //return address back to route object
        const newWaypoint = {placeId,label};
        addWaypoint(newWaypoint);
        if(name === 'waypoint' && this.searchInputRef.current){
            this.searchInputRef.current.value = '';
        }
    }



    isHandleChangeSet = (event) => {
        const {
            handleChange
        } = this.props;

        if(handleChange){
            return (event) => handleChange(event)
        }
        return ''
    }

    render() {
    const {
        name,
        placeholder,
        label
    } = this.props
        return (
            <AnimatedInput
                type="text"
                name={name}
                reference={this.searchInputRef}
                placeholder={placeholder ? placeholder : 'Voer een locatie in'}
                label={label}
                onChange={(event) => this.isHandleChangeSet(event)}
            />
        )
    }
}
PlacesAutocompleteInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    addWaypoint: PropTypes.func.isRequired

}
export default PlacesAutocompleteInput;