import React from 'react';
import { Router } from 'react-router';
import { inject, observer } from 'mobx-react';
import history from './utils/history';
import routes from './routes';
import ErrorBoundary from '../components/shared/ErrorBoundary';
import LoadingIndicator from '../components/shared/loading/loading-indicator';
import { Application } from './Application';
import store from './utils/store';
import { Provider as StoreProvider } from 'react-redux';
import config from '../config'


interface AppProps {
    application?: Application;
}

interface AppState {
    ready: boolean;
    googleMapsLoaded: boolean;
}

@inject('application')
@observer
class App extends React.Component<AppProps, AppState> {
    public readonly state: AppState = {
        ready: false,
        googleMapsLoaded: false
    }
    public async componentDidMount(): Promise<void> {
        const {
            application
        } = this.props;

        application!.initialize();

        if(!window.google){
            const googleMapsScript = document.createElement('script');

            googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${config.google_api_key}&libraries=places`;
            googleMapsScript.async = true;
            document.body.appendChild(googleMapsScript);

            googleMapsScript.addEventListener('load', () => {
                this.setState({
                    googleMapsLoaded: true
                })
            });
            return
        }
    }

    public render(): React.ReactNode {
        const { application } = this.props;
        const { googleMapsLoaded } = this.state;

        return (
            !application!.ready && !googleMapsLoaded ? (
                <div className="d-flex align-items-center justify-content-center vh-100 flex-column">
                    <LoadingIndicator />
                    We zetten alles voor u klaar!
                </div>
            ) : (
                <StoreProvider
                    store={store}
                >
                    <Router history={history}>
                        <ErrorBoundary>
                            {routes}
                        </ErrorBoundary>
                    </Router>
                </StoreProvider>
            )
        );
    }
}

export default App;







/*
import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { Router } from 'react-router';
import store from './utils/store';
import routes from './routes';
import history from './utils/history';
import config from '../config'
import LoadingIndicator from '../components/shared/loading/loading-indicator'

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            googleMapsLoaded: false

        }
    }

    componentDidMount () {
        if(!window.google){
            const googleMapsScript = document.createElement('script');

            googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${config.api_key}&libraries=places`;
            googleMapsScript.async = true;
            document.body.appendChild(googleMapsScript);

            googleMapsScript.addEventListener('load', () => {
                this.setState({
                    googleMapsLoaded: true
                })
            });
            return
        }
        return
    }
    scriptsLoaded = () => {
        const {
            googleMapsLoaded
        } = this.state;
        if(googleMapsLoaded){
            return (
                <Router history={history}>
                    {routes}
                </Router>
            )
        }
        return <LoadingIndicator></LoadingIndicator>
    }

    render() {
        return(
            <StoreProvider
                store={store}
            >
                {this.scriptsLoaded()}

            </StoreProvider>
        )
    }
}


export default App;*/
