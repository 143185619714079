import * as React from 'react';
import clsx from 'clsx';
import { Variations } from '../../../app/utils/interfaces/variations';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    variation: Variations;
    type?: 'submit' | 'button';
    block?: boolean;
    transparent?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    disabled?: boolean;
    icon?: React.ReactElement
}

const Button: React.FunctionComponent<ButtonProps> = ({
    className,
    children,
    variation,
    type,
    block,
    transparent,
    onClick,
    loading,
    disabled,
    icon
}: React.PropsWithChildren<ButtonProps>) => (
    // eslint-disable-next-line react/button-has-type
    <button
        className={clsx([
            'btn',
            `btn-${variation}`,
            block && 'btn-block',
            transparent && 'btn-transparent',
            loading && 'btn-loading',
            className
        ])}
        disabled={disabled || loading}
        aria-disabled={loading}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={onClick}
    >
        {icon && (
            <div className="icon-container">
                {icon}
            </div>
        )}
        <span className="btn-text">{children}</span>
    </button>
);

Button.defaultProps = {
    type: 'button'
};

export default Button;
