import React from 'react';
import Route from './route';
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import RoutesAction from '../../../app/actions/routes.action';
import history from '../../../app/utils/history';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Container from '../../../app/Container';

@inject('routes')
@observer
class RouteSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRoute: null,
            routeSelected: false,
        }
    }

    componentDidMount = () => {
        this.getRoutes()
    }

    getRoutes = () => {
        Container.get('routes').get({});
    }

    generateRoutes = () => {
        const {
            selectedRoute
        } = this.state

        const {
            routes: {
                Routes: {
                    routes
                }
            }
        } = this.props

        if(!routes){
            return ('loading')
        }

        const isRouteSelected = (id) => {
            if(id === selectedRoute) {
                return true
            }
            return false
        }

        return (
            routes.map(route => (
                <Route 
                    routeObject={route}
                    key={route.id}
                    selected={isRouteSelected(route.id)}
                    showOptions={true}
                    selectThisRoute={(id) => {
                        Container.get('routes').select(id);
                        this.setState({
                            selectedRoute: id,
                            routeSelected: true,
                        })
                    }}
                    refresh={() => this.getRoutes()}
                />
            ))
        )

    }

    startSelectedRoute = () => {
        const{
            routeSelected
        } = this.state;

        if(routeSelected){
            history.push('/map');
        }
    }

    getStartButtonCss = () => {
        const {
            routeSelected
        } = this.state;


        if (routeSelected){
            return "start-route-button drop-shadow"
        }
        return "start-route-button drop-shadow d-none"
    }

    render() {
        return (
            <Col className="h-100" sm={10}>
                <div className="mt-5">
                    <div className="routes-list">
                        {this.generateRoutes()}
                        <button
                            className="btn add-route-button drop-shadow"
                            onClick={ () => {history.push('/add-route') }}
                        >
                            Route toevoegen
                        </button>

                        <button 
                            className={this.getStartButtonCss()}
                            onClick={() => this.startSelectedRoute()}
                        >
                            Route starten
                        </button>
                    </div>
                </div>
            </Col>
        )
    }
}

RouteSelect.propTypes = {
    getRoutes: PropTypes.func.isRequired,
    selectRoute: PropTypes.func.isRequired,
    routeStore: PropTypes.shape({
        selectedRoute: PropTypes.oneOfType([
            PropTypes.object.isRequired,
            PropTypes.oneOf([null]).isRequired
        ]),
        routes: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                start: PropTypes.string.isRequired,
                end: PropTypes.string.isRequired,
                routeParams: PropTypes.arrayOf(PropTypes.string).isRequired
            }).isRequired
        ).isRequired
    })
}

const mapDispatchToProps = () => ({
    getRoutes: RoutesAction.get,
    selectRoute: RoutesAction.select
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps())(RouteSelect);