import React from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SideNavLinks from './side-nav-links';
import { GlobalStoreContract } from '../../../app/stores/global/GlobalStoreContract';
import { Application } from '../../../app/Application';

interface SideNavProps {
    global?: GlobalStoreContract;
    application?: Application;
}

@inject('global', 'application')
@observer
class SideNav extends React.Component<SideNavProps, {}> {
    private getToggleClasses() {
        const {
            global
        } = this.props;

        return `sidebar-${global!.menuCollapsed ? 'collapsed' : 'expanded'} d-md-block sidebar-container box-shadow`;
    }

    render() {
        const {
            global,
            application
        } = this.props;
        return (
            <div id="sidebar-container" className={this.getToggleClasses()}>
                <nav className="sidebar-nav">
                    <button
                        type="button"
                        className="sidebar-collapse"
                        onClick={() => {
                            global!.toggleMenu();
                        }}
                    >
                        {global!.menuCollapsed ? (
                            <FontAwesomeIcon className="icon-gradient" icon={faChevronRight} />
                        ) : (
                            <FontAwesomeIcon className="icon-gradient" icon={faChevronLeft} />
                        )}
                        <span className="sidebar-collapse-caption">{application?.configuration?.application_name || 'Inklappen'}</span>
                    </button>
                    <SideNavLinks />
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="absolute">
                        <defs>
                            <linearGradient id="linear" gradientTransform="rotate(45)">
                                <stop className="linear-stop1" offset="0%" />
                                <stop className="linear-stop2" offset="100%" />
                            </linearGradient>
                        </defs>
                    </svg>
                </nav>
            </div>
        );
    }
}

export default SideNav;