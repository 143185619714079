import React from 'react';
import Page from '../Page';
import EditRouteForm from '../../shared/forms/EditRouteForm';
import { Col } from 'react-bootstrap';

class EditRoute extends React.Component {
    render () {

        return(
            <Page title="Route aanpassen">
                <Col className="h-100" sm={12} >
                    <div className="add-route-page mt-5 h-100" >
                        <EditRouteForm/>
                    </div>
                </Col>
            </Page>
        )
    }
}

export default EditRoute;