import React from 'react';

class ChangableInputComponent<P, S> extends React.Component<P, S> {
    handleInputChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        callback?: Function
    ): void {
        event.preventDefault();

        const {
            name,
            value
        } = event.currentTarget;

        let realName: string;

        if (/^([\w](-|))+(\.[a-zA-Z]+)$/.test(name)) {
            [, realName] = name.split('.');
        } else {
            realName = name;
        }

        this.setState((prevState) => ({
            ...prevState,
            [realName]: value
        }), callback && callback());
    }
}

export default ChangableInputComponent;
