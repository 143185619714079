import { inject, injectable } from 'inversify';
import { observable } from 'mobx';
import { isEqual } from 'lodash';
import IntlMessageFormat from 'intl-messageformat';
import { ApiService } from './services/connections/rest/ApiService';
import { Configurations } from './services/configurations/Configurations';
import { AuthStoreContract } from './stores/auth/AuthStoreContract';
import { CacheStoreContract } from './stores/storage/caching/CacheStoreContract';
import config from '../config';
import { ResponseStatus } from './services/connections/rest/response/ResponseStatus';

@injectable()
export class Application {
    private api: ApiService;
    private auth: AuthStoreContract;
    private cache: CacheStoreContract;
    @observable
    private _ready = false;

    public constructor(
        @inject('api') api: ApiService,
        @inject('auth') auth: AuthStoreContract,
        @inject('cache') cache: CacheStoreContract
    ) {
        this.api = api;
        this.auth = auth;
        this.cache = cache;
    }

    public get ready() {
        return this._ready;
    }

    public get configuration(): Configurations | null {
        const configurations = localStorage.getItem('configurations');

        if (configurations) {
            return JSON.parse(configurations);
        }

        return null;
    }

    public set configuration(c: Configurations | null) {
        if (c) {
            localStorage.setItem('configurations', JSON.stringify(c));
        }
    }

    public async initialize(): Promise<void> {
        await this.updateConfiguration();

        this._ready = true;
    }

    private async updateConfiguration() {
        const configurations = await this.getConfigurations();
        let shouldReload = false;

        if (configurations) {
            if (
                !this.configuration
                || this.configuration.version !== configurations.version
            ) {
                await this.cache.clear();
                shouldReload = true;
            } else if (
                this.configuration.permissions_version !== configurations.permissions_version
                && this.auth.user
            ) {
                await this.auth.updatePermissions();
            }

            this.configuration = configurations;

            if (shouldReload) {
                window.location.reload();
            }
        }
    }

    public async getConfigurations(): Promise<Configurations | undefined> {
        const response = await this.api.get(
            new IntlMessageFormat(config.api.urls.configuration).format({ name: config.app_name }) as string,
            false
        );

        if (response.getStatus() === ResponseStatus.OK) {
            return response.getData() as Configurations;
        }

        return undefined;
    }

    public async fetchConfiguration(): Promise<boolean> {
        try {
            const configurations = await this.getConfigurations();

            if (configurations) {
                if (!this.configuration?.version) {
                    this.configuration = configurations;

                    return false;
                }

                const oldConfiguration = this.configuration;
                this.configuration = configurations;

                return !isEqual(oldConfiguration, configurations);
            }
        } catch (e) {
            return false;
        }

        return true;
    }
}
