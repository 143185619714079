import { inject, injectable } from 'inversify';
import { AuthStoreContract } from './AuthStoreContract';
import { LoadingStore } from '../loading/LoadingStore';
import { LoadingState } from '../loading/LoadingState';
import { ApiService } from '../../services/connections/rest/ApiService';
import { JwtObject } from '../../services/connections/rest/JwtObject';
import config from '../../../config';
import { ErrorResponse } from '../../services/connections/rest/response/ErrorResponse';
import history from '../../utils/history';
import { UserObject } from './UserObject';
import { ResponseStatus } from '../../services/connections/rest/response/ResponseStatus';
import { LoadingStoreContract } from '../loading/LoadingStoreContract';
import { PermissionsObject } from './PermissionsObject';
/* eslint-disable @typescript-eslint/camelcase */

@injectable()
export class AuthStore extends LoadingStore implements AuthStoreContract, LoadingStoreContract {
    private api: ApiService;

    private _user?: UserObject;

    public constructor(
        @inject('api') api: ApiService
    ) {
        super();

        this.api = api;
    }

    public async login(username: string, password: string): Promise<ErrorResponse | undefined> {
        this.setLoadingState('login', LoadingState.LOADING);

        const response = await this.api.post<JwtObject>(config.api.urls.oauth, {
            grant_type: config.grant_type,
            client_id: config.client_id,
            client_secret: config.secret_key,
            username,
            password,
            scope: '*'
        }, false);

        if (response.getData() !== null) {
            if ((response.getData() as ErrorResponse).errors) {
                this.setLoadingState('login', LoadingState.LOADED);
                return response.getData() as ErrorResponse;
            }

            // eslint-disable-next-line camelcase
            if ((response.getData() as JwtObject)?.access_token) {
                await localStorage.setItem('jwt', JSON.stringify(response.getData() as JwtObject));
                await this.fetchUser();
/*                await this.updatePermissions();*/

                history.push('/home');
            }

            this.setLoadingState('login', LoadingState.LOADED);

            return undefined;
        }

        // @todo render 500
        return undefined;
    }

    private async fetchUser(): Promise<void> {
        this.setLoadingState('fetch-user', LoadingState.LOADING);

        const response = await this.api.get<UserObject>(config.api.urls.user.info, true);

        this.setLoadingState('fetch-user', LoadingState.LOADED);

        if (response.getStatus() === ResponseStatus.OK) {
            this._user = response.getData() as UserObject;
            localStorage.setItem('user', JSON.stringify(response.getData()));
        } else {
            await this.logout();
        }
    }

    public get user(): UserObject | null {
        if (!this._user) {
            if (!localStorage.getItem('user')) {
                return null;
            }

            this._user = JSON.parse(localStorage.getItem('user')!);
        }

        return this._user || null;
    }

    public updateUser(key: keyof UserObject, value: any) {
        if (!this.user) {
            return;
        }

        this.user[key] = value;
        localStorage.setItem('user', JSON.stringify(this.user));
    }

    public logout(): void {
        this._user = undefined;
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        history.push('/login');
    }

    public async updatePermissions(): Promise<PermissionsObject> {
        const response = await this.api.get<PermissionsObject>(config.api.urls.user.permissions, true);

        if (response.getStatus() === ResponseStatus.OK) {
            this.updateUser('permissions', (response.getData() as PermissionsObject).data);
        }

        return response.getData() as PermissionsObject;
    }
}
