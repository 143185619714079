import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import PlacesAutocompleteInput from '../places/places-search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandPaper, faMapMarkerAlt, faTrash, faEdit} from '@fortawesome/free-solid-svg-icons';
import history from '../../../app/utils/history';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AnimatedInput from "../input/AnimatedInput";
import {inject, observer} from "mobx-react";
import Container from '../../../app/Container';

@inject('routes')
@observer
class AddRouteForm extends React.Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.importRef = React.createRef();

        this.state = {
            formValues: {
                name: '',
                description: '',
                description_link: '',
                start: {},
                end: {},
                routeParams: [],
                travelModes: []
            },
            formValid: true,
            showSightModal: false,
            activeSightInModal: null,
            activeSightLink:''
        };
    }
    waypointRef = React.createRef();

    handleChange = (event) => {
        const {
            formValues
        } = this.state;
        const {
            name,
            value
        } = event.target;
        formValues[name] = value;
        this.setState({ formValues });
    }

    changeTravelmode = (event) => {
        const {
            formValues
        } = this.state;
        const {
            value,
            name
        } = event.target;

        let newTravelmodes = [...formValues.travelModes];
        newTravelmodes[name] = value;
        this.setState({
            formValues: {
                ...formValues,
                travelModes: newTravelmodes
            }
        })
    }
    /**
     * @param {object} waypoint - Waypoint for google maps api
     * @param {string} waypoint.location - Address used by google maps api
     * @param {bool} waypoint.stopover - 
     */
    addWaypoint = (place) => {
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: [
                    ...prevState.formValues.routeParams,
                    place
                ]
            }
        }));
        if(this.waypointRef.current){
            this.waypointRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }
    
    addStart = (place) => {
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                    start: place

            }
        }));
    }

    addEnd = (place) => {
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                    end:place

            }
        }));
    }

    deleteWaypoint = (index) => {
        const { 
            formValues: {
                routeParams
            } 
        } = this.state
        
        const newRouteParams = routeParams.slice();
        newRouteParams.splice(index, 1)

        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: [
                    ...newRouteParams
                ]
            }
        }));

    }

    toggleIsStop = (index) => {
        const {
            formValues: {
                routeParams
            }
        } = this.state

        let newRouteParams = routeParams.slice();
        newRouteParams[index].isStop = !newRouteParams[index].isStop;

        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: [
                    ...newRouteParams
                ]
            }
        }));
    }

    openSightDescriptionModal = (index) => {
        this.setState(prevState => ({
            ...prevState,
            showSightModal: !prevState.showSightModal,
            activeSightInModal: index
        }));
    }

    changeSightDescriptionLink = (event) => {
        const {
            value
        } = event.target;
        this.setState({
            activeSightLink: value
        })
    }

    editSightDescription = (event) => {
        event.preventDefault();
        const {
            formValues: {
                routeParams
            },
            activeSightInModal,
            activeSightLink
        } = this.state;

        let newRouteParams = [...routeParams]
        newRouteParams[activeSightInModal].description_link = activeSightLink;

        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: [
                    ...newRouteParams
                ]
            },
            showSightModal: false,
        }));
    }


    showWaypoints = (routeParams,offset, draggableOffset, droppableId) => {

        if(!draggableOffset) draggableOffset = 0;
        if(!offset) offset = 0;
        const renderWaypoints = () => routeParams.map( (waypoint, index) => {
            return(
                <Draggable draggableId={`${waypoint.label ? waypoint.label: waypoint.address}${index + draggableOffset}`} key={index + draggableOffset} index={index + offset}>
                    { (provided, snapshot) => (
                        <div className={`waypoint ${waypoint.isStop ? 'is-stop' : ''}`}  key={index + draggableOffset} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            <p>{waypoint.label ? waypoint.label: waypoint.address}</p>
                            <a  target="_blank" className="sight-link" href={waypoint.description_link ? waypoint.description_link :'#'}> {waypoint.description_link}</a>

                            <button type="button" className="waypoint-btn-1" onClick={() => this.openSightDescriptionModal((index + offset), )}>
                                <FontAwesomeIcon icon={faEdit}/>
                            </button>
                            <button type="button" className="mr-5" onClick={() => this.toggleIsStop(index + offset)}>
                                <FontAwesomeIcon icon={faHandPaper}/>
                            </button>
                            <button type="button" className="delete" onClick={() => this.deleteWaypoint(index + offset)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </div>
                    )}
                </Draggable>
            )
        })

        return(
            <Droppable droppableId={`${droppableId}`}>
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {renderWaypoints()}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )

    }

    OrganiseRoute = () => {
        const {
            formValues: {
                routeParams,
                travelModes
            }
        } = this.state;

        const splitRoutes = () => {
            const {
                formValues : {
                    travelModes
                },
                formValues
            } = this.state;

            let i = 0;
            let routes = [];
            let currentRoute = 0;
            let itemsPerRoute = 24;

            routeParams.forEach( item => {
                if(!routes[currentRoute]) {
                    routes[currentRoute] = [];
                }
                routes[currentRoute].push(item);
                if(travelModes.length === (currentRoute)){
                    const newTravelmodes = [...travelModes];
                    newTravelmodes[currentRoute] = "DRIVING";
                    this.setState({
                        formValues: {
                            ...formValues,
                            travelModes: newTravelmodes
                        }
                    })
                }

                i++
                if(item.isStop) {
                    i = 0;
                    itemsPerRoute = 23;
                    currentRoute++;

                    if(!routes[currentRoute]) {
                        routes[currentRoute] = [];
                    }

                    routes[currentRoute].push(routes[currentRoute - 1][routes[currentRoute - 1].length - 1])
                }
                if(i === itemsPerRoute) {
                    i = 0;
                    itemsPerRoute = 23;
                    currentRoute++;

                    if(!routes[currentRoute]) {
                        routes[currentRoute] = [];
                    }

                    routes[currentRoute].push(routes[currentRoute - 1][routes[currentRoute - 1].length - 1])
                }
            });
            return routes;
        }
        return splitRoutes();
    }

    addRoute = async (event) => {
        event.preventDefault();
        const {
            formValues
        } = this.state;
        await Container.get('routes').post(formValues);
        history.push('/');
    }

    importRoute = async (event) => {
        let reader = new FileReader();
        if(event.target.files){
            reader.onload = () => {
                const routeObject = JSON.parse(reader.result);
                this.importToState(routeObject)
            }
            reader.readAsText(event.target.files[0]);
        }
    }

    importToState = (routeObject) => {
        const {
            formValues
        } = this.state;
        const that = this;
        const p = new Promise((resolve) => {
            resolve({
                name: routeObject.name,
                start: routeObject.start,
                end: routeObject.end,
                routeParams: routeObject.routeParams
            });
        });
        p.then((newFormValues) => {
            newFormValues.routeParams = newFormValues.routeParams.map((item) => {
                return {
                    address: item.address ? item.address : undefined,
                    isStop: item.isStop ? item.isStop : false,
                    placeId: item.placeId ? item.placeId : undefined,
                    label: item.label ? item.label : undefined
                }
            })
            if(!newFormValues.end.placeId || !newFormValues.start.placeId){
                newFormValues.start = {
                    address: newFormValues.start
                }
                newFormValues.end = {
                    address: newFormValues.end
                }
            }
            that.setState({ formValues: {...formValues, ...newFormValues} });
        })
    }

    showPlacesInput = (array) => {
        if(array.length >= 24){
            return;
        }
        return(
            <PlacesAutocompleteInput
            addWaypoint={(waypoint) => this.addWaypoint(waypoint)}
            name="waypoint"
        />
        )
    }

    showForm = () => {
        const {
            formValues: {
                routeParams,
                name,
                start,
                end,
                description,
                description_link,
                travelModes
            }
        } = this.state;

        const form = [];
        let sights = this.OrganiseRoute();
        const getOffset = (index) => {
            let offset = 0;
            for(let i = 0; i < index ; i++ ){
                offset = offset + sights[i].length;
            }
            return offset - index;
        }
        const getDraggableOffset = (index) => {
            let offset = 0;
            for(let i = 0; i < index ; i++ ){
                offset = offset + sights[i].length;
            }
            return offset ;
        }

        const generateFormParts = () => {
            let i = 0;
            let firstRoute = true;
            if (sights.length === 0) sights = ['empty'];
            sights.forEach((sight) => {
                if(firstRoute){
                    form.push(
                        <>
                            <Form.Group
                                className="form-group-container"
                            >
                                <Form.Group>
                                    <div className="row">
                                        <div className="col-auto">
                                            <Form.Label>Informatie</Form.Label>
                                        </div>
                                        <div className="col-auto ml-auto">
                                            <label className="import-label" htmlFor="import-file">Importeer route</label>
                                            <input className="import-input" ref={this.importRef} type="file" accept=".sightseeingapp" id="import-file" onChange={this.importRoute}/>
                                        </div>
                                    </div>
                                </Form.Group>
                                <AnimatedInput
                                    type="text"
                                    name="name"
                                    value={name}
                                    placeholder="Naam van route"
                                    label="Naam van route"
                                    onChange={this.handleChange}
                                />
                                <AnimatedInput
                                    as="textarea"
                                    rows="3"
                                    type="text"
                                    name="description"
                                    value={description}
                                    placeholder="Beschrijving"
                                    label="Beschrijving"
                                    onChange={this.handleChange}
                                />
                                <AnimatedInput
                                    type="text"
                                    name="description_link"
                                    value={description_link}
                                    placeholder="Link"
                                    label="Voeg een link toe"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group
                                className="form-group-container"
                            >
                                <div className="row">
                                    <div className="col-auto">
                                        <Form.Label>Start en Eind</Form.Label>
                                    </div>
                                    <div className="col-auto ml-auto">
                                        <button
                                            type="button"
                                            className="btn optimize-btn"
                                            onClick={() => this.optimizeRoute()}
                                        >
                                            Optimaliseer route
                                        </button>
                                    </div>
                                </div>

                                <PlacesAutocompleteInput
                                    addWaypoint={(waypoint) => this.addStart(waypoint)}
                                    name="start"
                                    placeholder={start.label ? `Start locatie: ${start.label}` : `Start locatie: ${start.address}`}
                                    handleChange={this.handleChange}
                                    label="Start locatie"
                                />
                                <PlacesAutocompleteInput
                                    addWaypoint={(waypoint) => this.addEnd(waypoint)}
                                    name="end"
                                    placeholder={end.label ? `Eind locatie: ${end.label}` : `Eind locatie: ${end.address}`}
                                    handleChange={this.handleChange}
                                    label="Eind locatie"
                                />
                            </Form.Group>
                            <Form.Group
                                className="form-group-container"
                            >
                                <div className="row">
                                    <div className="col-auto">
                                        <Form.Label>Route deel 1</Form.Label>
                                    </div>
                                    <div className="col-auto ml-auto">
                                        <div className="travelmode-select">
                                            <select value={travelModes[i]} name={i} onChange={(event) => this.changeTravelmode(event)}>
                                                <option value="DRIVING">Auto</option>
                                                <option value="WALKING">Lopen</option>
                                                <option value="TRANSIT">Openbaarvervoer</option>
                                                <option value="BICYCLING">Fiets</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {sights[0] === 'empty' ? '':this.showWaypoints(sights[i],0,0,i)}
                                {this.showPlacesInput(sights[i])}
                            </Form.Group>
                        </>
                    )
                    firstRoute = false;
                } else {
                    form.push(
                        <Form.Group
                            className="form-group-container"
                        >
                            <div className="row">
                                <div className="col-auto">
                                    <Form.Label>{`Route deel ${i + 1}`}</Form.Label>
                                </div>
                                <div className="col-auto ml-auto">
                                    <div className="travelmode-select">
                                        <select value={travelModes[i]} name={i} onChange={(event) => this.changeTravelmode(event)}>
                                            <option value="DRIVING">Auto</option>
                                            <option value="WALKING">Lopen</option>
                                            <option value="TRANSIT">Openbaarvervoer</option>
                                            <option value="BICYCLING">Fiets</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {this.showWaypoints(sights[i],getOffset(i),getDraggableOffset(i),i)}
                            {this.showPlacesInput(sights[i])}
                        </Form.Group>
                    )
                }
                i++;
            })

            return form.map(formPart => {
                return formPart
            })
        }
        return(
            <>
                <Form
                    className="new-route-form"
                    onSubmit={this.addRoute}
                    onKeyDown={(event) => {
                        if(event.key === 'Enter'){
                            event.preventDefault()
                            return event.key !== 'Enter'
                        }
                    }}
                >
                    {generateFormParts()}
                    <button
                        type="submit"
                        className="add-route-button drop-shadow"
                    >
                        Route Opslaan
                    </button>
                    <div ref={this.waypointRef} className="scroll-target"></div>
                </Form>
            </>
        )
    }

    onDragEnd = (result) => {
        const {
            formValues: {
                routeParams
            }
        } = this.state;

        const {
            destination,
            source,
            draggableId
        } = result;

        if(!destination) return;
        let destinationIndex = destination.index;
        const sourceIndex = source.index;
        const item = routeParams[sourceIndex];

        if(source.droppableId < destination.droppableId){
            destinationIndex--
        }

        const newRouteParams = [...routeParams];
        newRouteParams.splice(sourceIndex,1);
        newRouteParams.splice(destinationIndex, 0, item);

        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: [
                    ...newRouteParams
                ]
            }
        }));
    }


    setNewFormValues = (organizedArray) => {
        const newRouteParams = [];
        let optimizedRoutesArray = organizedArray.map(route => {
            route.forEach(sight => {
                newRouteParams.push(sight);
            })
        })
        return [...new Set(newRouteParams)]
    }

    optimizeRoute = async () => {
        const {
            formValues: {
                travelModes,
                end,
                start
            }
        } = this.state;

        //get directions render from googlemaps api
        const directionsService = new window.google.maps.DirectionsService();
        // get split route
        const splitRoutesArray = this.OrganiseRoute();

        //loop over routesArray
        const reOrder = async () => {
            let optimizedRoutesArray = await Promise.all(splitRoutesArray.map((route, i) => {

                return new Promise(resolve => {
                    const route = splitRoutesArray[i]
                    if(travelModes[i] === "FLYING") resolve(splitRoutesArray[i]);
                    let routeClone = route.slice();

                    let startPoint;
                    let endPoint;
                    if (i === 0) {
                        startPoint = {'placeId': start.placeId}
                    } else {
                        startPoint = {'placeId': route[0].placeId};
                    }

                    if (i === splitRoutesArray.length - 1) {
                        endPoint = {'placeId': end.placeId};
                    } else {
                        endPoint = {'placeId': route[route.length - 1].placeId};
                    }

                    let waypoints = routeClone.map((waypoint, index) => {
                        if (index === 0 && i !== 0 || index === routeClone.length - 1 && i !== splitRoutesArray.length - 1) return;
                        return {
                            location: {'placeId': waypoint.placeId},
                            stopover: true
                        }
                    }).filter((value) => typeof value !== 'undefined');



                    // do request to directionsService
                    directionsService.route({
                        origin: startPoint,
                        destination: endPoint,
                        waypoints: waypoints,
                        optimizeWaypoints: true,
                        travelMode: travelModes[i]
                    }, (response, status) => {
                        if (status === 'OK') {
                            reorderExistingRoute(response);
                        } else {
                            window.alert('Er ging iet mis door ');
                        }
                    })

                    const reorderExistingRoute = (response) => {
                        const {
                            geocoded_waypoints
                        } = response;
                        routeClone = route.slice();
                        const optimizedRoute = [];

                        if (i !== 0) optimizedRoute.push(routeClone[0]);

                        geocoded_waypoints.forEach((reorderedSight) => {
                            optimizedRoute.push(
                                routeClone.find((sight) => {
                                    return sight.placeId === reorderedSight.place_id;
                                })
                            )
                        })

                        if (i !== splitRoutesArray.length - 1) optimizedRoute.push(routeClone[routeClone.length - 1]);

                        resolve(optimizedRoute.filter(value => typeof value !== 'undefined'))
                    }
                })
            }))
            return(this.setNewFormValues(optimizedRoutesArray))
        }
        const newrouteParams = await reOrder();
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                routeParams: newrouteParams
            }
        }));
    }

    render() {
        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {this.showForm()}
                </DragDropContext>
                <Modal centered show={this.state.showSightModal} backdrop={true} className="logout-modal">
                    <Modal.Body>
                        <Form
                            onSubmit={this.editSightDescription}
                        >
                            <AnimatedInput
                                type="text"
                                placeholder="Voeg een link toe"
                                label="Voeg een link toe"
                                onChange={this.changeSightDescriptionLink}
                            />
                            <button type="submit" className="btn" size="sm" variant="danger" onClick={this.logout}>
                                Opslaan
                            </button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default AddRouteForm;

