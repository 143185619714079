import * as Axios from 'axios';
import { injectable } from 'inversify';
import { JwtObject } from './JwtObject';
import Container from '../../../Container';
import { RequestHandler } from './RequestHandler';
import appConfig from '../../../../config';
import { ErrorResponse } from './response/ErrorResponse';
import { Response } from './response/Response';

@injectable()
export class ApiService {
    private tokenInfo: JwtObject | null = null;

    private client: Axios.AxiosInstance;

    public constructor() {
        this.client = Axios.default.create({
            baseURL: appConfig.base_url,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
    }

    public async request<T, E extends ErrorResponse = ErrorResponse>(
        config: Axios.AxiosRequestConfig,
        token = true
    ): Promise<Response<T> | Response<E>> {
        const responseService = Container.get<RequestHandler<T, E>>('responseService');

        if (token) {
            if (!this.tokenInfo) {
                await this.setTokenInfo();
            }

            config = {
                ...config,
                headers: {
                    ...config.headers,
                    ...this.getAccessHeaders()
                }
            };
        }

        return responseService.handleRequest(
            this.client.request<T>(config)
        );
    }

    public post<T, E extends ErrorResponse = ErrorResponse>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        token = true
    ): Promise<Response<T> | Response<E>> {
        return this.request<T, E>({
            method: 'post',
            url,
            data
        }, token);
    }

    public get<T, E extends ErrorResponse = ErrorResponse>(
        url: string,
        token = true
    ): Promise<Response<T> | Response<E>> {
        return this.request<T, E>({
            method: 'get',
            url
        }, token);
    }

    public put<T, E extends ErrorResponse = ErrorResponse>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        token = true
    ): Promise<Response<T> | Response<E>> {
        return this.request<T, E>({
            method: 'patch',
            url,
            data
        }, token);
    }

    public delete<T, E extends ErrorResponse = ErrorResponse>(
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any = {},
        token = true
    ): Promise<Response<T> | Response<E>> {
        return this.request<T, E>({
            method: 'delete',
            url,
            data
        }, token);
    }

    private setTokenInfo(): Promise<JwtObject> {
        return new Promise((resolve, reject) => {
            const info = localStorage.getItem('jwt');
            if (info) {
                this.tokenInfo = JSON.parse(info);
                resolve(this.tokenInfo as JwtObject);
            } else {
                reject(new Error('No jwt info found'));
            }
        });
    }

    public getAccessHeaders(): { Authorisation: string } | {} {
        const jwt = localStorage.getItem('jwt');
        const tokenObject = jwt && JSON.parse(jwt);

        if (tokenObject) {
            return {
                Authorization: `Bearer ${tokenObject.access_token}`
            };
        }

        return {};
    }
}
