import { types } from './types';
import RouteRepository from '../database/repositories/route-repository';

class RoutesAction {
    static get(filterParams){
        return async (dispatch) => {

            try {
                const routes = await RouteRepository.getRoute(filterParams);
                dispatch({ type: types.ROUTE_GET, payload: routes})
            } catch(event) {
                console.log('something went wrong while Getting route from database');
            }

        }
    }

    static post(route){
        return async (dispatch) => {

            try {
                const response = await RouteRepository.postRoute(route);

                dispatch({ type: types.ROUTE_POST, payload: response})
            } catch(event) {
                console.log('something went wrong while Posting route to database');
            }

        }
    }

    static put(route) {
        console.log(route)
        return async (dispatch) => {
            try {
                await RouteRepository.putRoute(route)
                dispatch({type: types.ROUTE_PUT, payload: route})
            } catch {
                console.log('something went wrong while Posting route to database');
            }
        }
    }

    static delete(id) {
        console.log("id in action", id)
        return async (dispatch) => {
            try {
                dispatch({type: types.ROUTE_DELETE, payload: id})
                await RouteRepository.deleteRoute(id)
            }catch {
                console.log('something went wrong while Deleting route from database');
            }

        }
    }

    static select(id) {
        return async (dispatch) => {
            try {
                const route = await RouteRepository.getRoute({filterById: id});
                dispatch({type: types.ROUTE_SELECT, payload: route})
            } catch {
                console.log('something went wrong while Selecting route');
            }
        }
    }
}
export default RoutesAction;
