import Validator from './Validator';

class FormValidator extends Validator {
    validateRequired(el) {
        if (el instanceof HTMLInputElement
            || el instanceof HTMLTextAreaElement
        ) {
            return !!el.value.trim().length;
        }

        if (el instanceof HTMLSelectElement) {
            return !!el.options[el.selectedIndex].value.length;
        }

        return false;
    }

    validateMin(el, minValue) {
        if (el instanceof HTMLInputElement) {
            return el.value >= parseFloat(minValue);
        }

        if (el instanceof HTMLSelectElement) {
            return parseFloat(el.options[el.selectedIndex].value) >= parseFloat(minValue);
        }

        return false;
    }

    validateMax(el, maxValue) {
        if (el instanceof HTMLInputElement) {
            return el.value <= parseFloat(maxValue);
        }

        if (el instanceof HTMLSelectElement) {
            return parseFloat(el.options[el.selectedIndex].value) <= parseFloat(maxValue);
        }

        return false;
    }

    validateEmail(el) {
        if (el instanceof HTMLInputElement) {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(el.value);
        }

        return false;
    }

    validateConfirmed(el) {
        const other = this.getElementWhereName(`${el.getAttribute('name')}_confirmation`);

        return other && String(el.value) === String(other.value);
    }
}

export default FormValidator;
