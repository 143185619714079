import * as Axios from 'axios';
import { inject, injectable } from 'inversify';
import { Response } from './Response';
import { ErrorResponse } from './ErrorResponse';
import { RequestHandler } from '../RequestHandler';
import { NotificationStoreContract } from '../../../../stores/notifications/NotificationStoreContract';
import { Variations } from '../../../../utils/interfaces/variations';
import { ResponseStatus } from './ResponseStatus';

@injectable()
export class ResponseService<T, E extends ErrorResponse = ErrorResponse> implements RequestHandler<T, E> {
    private response?: Response<T> | Response<E>;

    private notificationsStore: NotificationStoreContract;

    public constructor(
        @inject('notifications') notificationsStore: NotificationStoreContract
    ) {
        this.notificationsStore = notificationsStore;
    }

    public async handleRequest(request: Promise<Axios.AxiosResponse<T | E>>): Promise<Response<T> | Response<E>> {
        try {
            this.response = new Response(await request) as Response<T>;

            return this.handleResponse();
        } catch (e) {
            if (e.response) {
                this.response = new Response<E>(e.response) as Response<E>;

                return this.handleErrorResponse();
            }
        }

        return this.makeNoResponseError() as Response<E>;
    }

    // @todo add redirect response
    private handleResponse(): Response<T> {
        if (this.response?.getStatus() === ResponseStatus.OK) {
            return this.response as Response<T>;
        }

        return this.response as Response<T>;
    }

    private handleErrorResponse(): Response<E> {
        if ((this.response?.getStatus() as number) >= 500) {
            this.notificationsStore.addNotification({
                variation: Variations.DANGER,
                message: 'Oops, er is iets misgegaan'
            });
        }

        return this.response as Response<E>;
    }

    private makeNoResponseError(): Response<ErrorResponse> {
        this.notificationsStore
            .addNotification({
                message: 'Geen verbinding met de server mogelijk',
                variation: Variations.WARNING,
                autoClose: true
            });

        // probably no internet connection
        return Response.make({
            message: 'No response body found'
        });
    }
}
