import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SideNav from '../shared/side-nav/side-nav';
import PropTypes from 'prop-types';

class Page extends React.Component {
    getTitle = () => {
        const {
            title
        } = this.props

        if(title){
            return(
                <Col sm={12}>
                    <h1 className="page-title">{title}</h1>
                </Col>
            )
        }
        return;
    }

    render () {
        const {
            children
        } = this.props;

        return (
            <Row className="body-row ">
                <SideNav />
                <Col className="page">
                    <Row className="ml-0 mr-0">
                        {this.getTitle()}

                        {children}
                    </Row>
                </Col>
            </Row>
        )
    }
}

Page.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired
}

export default Page;
