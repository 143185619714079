import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faShare, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import RoutesAction from '../../../app/actions/routes.action';
import history from '../../../app/utils/history';
import PropTypes from 'prop-types';
import Container from "../../../app/Container";

class Route extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
        }
    
    }
    shareRef = React.createRef();

    getMenuCss = () => {
        const {
            selected
        } = this.props;


        if (selected){
            return "share-menu active"
        }
        return "share-menu"
    }

    getSelectedCss = () => {
        const {
            selected
        } = this.props;


        if (selected){
            return "selected-route-list-item drop-shadow "
        }
        return "route-list-item drop-shadow"
    }

    deleteRoute = async() => {
        const {
            routeObject: {
                id
            },
            refresh
        } = this.props
        await Container.get('routes').delete(id);
        refresh();
    }

    editRoute = () => {
        history.push(`edit-route`)
    }

    shareRouteBtn = (event) => {
        const {
            routeObject
        } = this.props

        let json = JSON.stringify(routeObject);
        let blob = new Blob([json], { type: "text/plain" });
        let url = window.URL.createObjectURL(blob);

        this.shareRef.current.href = url;
        this.shareRef.current.target = '_blank';

        this.shareRef.current.download = `${routeObject.name}.sightseeingapp`;
    }

    showOptions = () => {
        const {
            showOptions,
            selected,
            routeObject: {
                name,
                id,
                description,
                description_link
            }
        } = this.props;

        if(showOptions){
            return (
                <>
                    <div className="info">
                        <p className="title">{name}</p>
                        <div className={this.getMenuCss()}>
                            <div className="share-btn">
                            </div>
                            <div className="social-btn" onClick={() => this.editRoute(id)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </div>
                            <a href="#/" className="social-btn" ref={this.shareRef} onClick={this.shareRouteBtn}>
                                <FontAwesomeIcon icon={faShare} />
                            </a>
                            <div className="social-btn" onClick={this.deleteRoute}>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </div>
                        <div className="content">
                            <p>{description ? description : ' '}</p>
                            <a target="blank" href={description_link ? description_link: '#'}>{description_link ? description_link: ' '}</a>
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="info">
                    <p className="title">{name}</p>
                    <div className={this.getMenuCss()}>
                        <div className="share-btn">
                        </div>
                        <div className="social-btn" onClick={() => this.editRoute(id)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </div>
                        <a href="#/" className="social-btn" ref={this.shareRef} onClick={this.shareRouteBtn}>
                            <FontAwesomeIcon icon={faShare} />
                        </a>
                        <div className="social-btn" onClick={this.deleteRoute}>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    </div>
                    <div className="content">
                        <p>{description ? description : ' '}</p>
                        <a target="blank" href={description_link ? description_link: '#'}>{description_link ? description_link: ' '}</a>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const {
            selectThisRoute,
            routeObject: {
                id
            }
        } = this.props

        return(
            <Col className={this.getSelectedCss()} onClick={() => selectThisRoute(id)}>
                {this.showOptions()}
            </Col>
        )
    }
}

Route.propTypes = {
    deleteRoute: PropTypes.func.isRequired,
    selectThisRoute: PropTypes.func.isRequired,
    showOptions: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    routeObject: PropTypes.shape({
        end: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        routeParams: PropTypes.arrayOf(
            PropTypes.string
        ).isRequired
    }).isRequired
}

const mapDispatchToProps = () => ({
    deleteRoute: RoutesAction.delete,
});

export default connect(null, mapDispatchToProps())(Route);
